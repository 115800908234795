﻿class CookieInfo {
    constructor(element) {
        this.element = element;
        this.initCookie();
        this.setupClickEvents();
    }

    setupClickEvents() {
        const cookieButton = this.element.querySelector('#cookie-button');
        if (cookieButton) {
            cookieButton.addEventListener('click', (event) => {
                // Set cookie
                document.cookie = "cookieConsent=true; max-age=" + (365 * 24 * 60 * 60) + "; path=/";
                console.log('Cookie set:', document.cookie);

                // Remove in UI
                this.hideElement();

                event.preventDefault();
            });
        }
    }

    initCookie() {
        console.log('Checking cookie...');
        if (!this.getCookie('cookieConsent')) {
            console.log('Cookie not found, showing banner.');
            this.showElement();
        } else {
            console.log('Cookie found, hiding banner.');
            this.hideElement();
        }
    }

    showElement() {
        // Remove both classes
        this.element.classList.remove('hidden', 'visually-hidden');
    }

    hideElement() {
        // Add both classes
        this.element.classList.add('hidden', 'visually-hidden');
    }

    getCookie(name) {
        const cookies = document.cookie.split(';').map(cookie => cookie.trim());
        for (let cookie of cookies) {
            const [key, value] = cookie.split('=');
            if (key === name) {
                return value;
            }
        }
        return null;
    }
}

// Init the widget
document.addEventListener('DOMContentLoaded', () => {
    const cookieInfoElement = document.querySelector('#cookie-info');
    if (cookieInfoElement) {
        new CookieInfo(cookieInfoElement);
    }
});
